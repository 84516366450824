import React from "react";
import { Grid, Box } from "@mui/material";
import MapToolBar from "../../../components/mapData/MapToolBar";
import "./preMapData.scss";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/pre_processor/actions";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Label from "../../../components/label/Label";
import PdfICon from "../../../components/icons/PdfIcon";
import ImageMapper from "react-img-mapper";
import PrismaZoom from "react-prismazoom";
import { CircularProgress } from "@mui/material";
import ProgressBar from "../../../components/ProgressBar";
import MapDataTable from "../../../components/MapDataTable/TemplateDriven/MapDataTable";
import CNCTableWrapper from "../../../components/MapDataTable/Free-Form/CNCTableWrapper";
import IconButton from "@mui/material/IconButton";
import AngleArrowLeft from "../../../assets/angle-circle-left-icon.svg";
import AngleArrowRight from "../../../assets/angle-circle-right-icon.svg";
import cloneDeep from "lodash/cloneDeep";
import TableSkeleton from "../../../components/TableSkeleton";

const styles = {
  margin: 0,
  fontSize: "10px",
  color: "#202020",
  fontfamily: "Montserrat",
};
const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    className="slick-prev"
    variant="contained"
    size="medium"
    color="primary"
  >
    Prev
  </button>
);
const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      className="slick-next"
      onClick={() => onClick()}
      variant="contained"
      size="medium"
      color="primary"
    >
      Next
    </button>
  );
};

class MapData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coorData: [],
      selectedFile: {
        tagName: "",
        fileNo: 0, // index is considered as file
        pageNo: 0,
      },
      zoom: 1,
      prismaZoom: React.createRef(),
      allowZoom: true,
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
      },
      pageRotateAngle: 0,
      imageLoaded: false,
      selectedArea: {},
      areaIndex: null,
      data: [],
      sizes: [100, "30%", "auto"],
      // page: 0,
      showPageAfterDelay: false,
      isFullHeight: true,
      originalMapperWidth: 600,
      originalMapperHeight: 768,
      imageMapperWidth: 600,
      imageMapperHeight: 768,
      coorDataFromState: [],
      coorDataFromStateCopy: [],
      entireCoorDataFromResToState: [],
    };
    this.checkIsImageLoadable = this.checkIsImageLoadable.bind(this);
  }

  componentDidMount() {
    if (this?.props?.isFullView) {
      this.setDataToState(this?.props?.coorData); // remove when sorting logic shifted to be
      const initialFileIndex = 0;
      this.checkIsImageLoadable(initialFileIndex, 0); // the second argument is current page number
    }
    if (!this?.props?.isFullView) {
      this.setDataToState(this?.props?.coorData); // remove when sorting logic shifted to be
      const initialFileIndex = 0;
      this.checkIsImageLoadable(initialFileIndex, 0); // the second argument is current page number
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setDataToState(nextProps?.coorData);
  }

  setDataToState(coorData) {
    // used this to set first pdf selected in initial state  and temp setting some initialn data
    if (coorData.length > 0) {
      const initialFileIndex = 0;
      this.setState({
        selectedFile: {
          tagName: coorData[0]?.pdfName,
          fileNo: 0,
          pageNo: 0,
        },
      });

      if (coorData?.length) {
        let sortedCoorData = coorData;

        sortedCoorData.map((fileData, index) => {
          const sortedPagesData = fileData.pages.sort((a, b) => {
            return parseInt(a.pageNo) - parseInt(b.pageNo);
          });
          sortedCoorData[index]["pages"] = sortedPagesData;
        });

        this.setState({ data: { ...sortedCoorData[0]?.pages[0] } }); // initial page setting
        this.setState({
          dataCopy: cloneDeep(
            JSON.parse(JSON.stringify(sortedCoorData[0]?.pages[0]))
          ),
        }); // initial page setting
        this?.setState({ coorDataFromState: sortedCoorData });
        this.setState({ coorDataFromStateCopy: cloneDeep(sortedCoorData) });
      }

      this.checkIsImageLoadable(initialFileIndex, 0); // the second argument is current page number
    }
  }
  handleFileChange(tagName, index) {
    this.setState({
      selectedFile: {
        // ...this.state.selectedFile,
        tagName: tagName,
        fileNo: index,
        pageNo: 0,
      },
      pageRotateAngle: 0,
      data: this?.state?.coorDataFromState[index]?.pages[0],
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });
    this.state.prismaZoom.current.reset();
    this.checkIsImageLoadable(index, 0);
  }

  handlePageChange = (value) => {
    this.setState({
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });
    if (value === "prev" && this.state.selectedFile?.pageNo !== 0) {
      const prevPageNo = this.state.selectedFile?.pageNo - 1;
      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: prevPageNo,
        },
        pageRotateAngle: 0,
        data: this?.state?.coorDataFromState[this.state?.selectedFile?.fileNo]
          ?.pages[prevPageNo],
      });
      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, prevPageNo);
    } else if (value === "next") {
      const nextPageNo = this.state.selectedFile?.pageNo + 1;

      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: nextPageNo,
        },
        pageRotateAngle: 0,
        data: this?.state?.coorDataFromState[this.state?.selectedFile?.fileNo]
          ?.pages[nextPageNo],
      });
      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, nextPageNo);
    }
  };

  searchPageClick = (value) => {
    if (
      value <
        this?.state?.coorDataFromState[this.state.selectedFile?.fileNo].pages
          .length &&
      value >= 0
    ) {
      this.setState({
        selectedFile: {
          ...this.state.selectedFile,
          pageNo: Number(value),
        },
        pageRotateAngle: 0,
        data: this?.state?.coorDataFromState[this.state?.selectedFile?.fileNo]
          ?.pages[Number(value)],
        imageMapperWidth: this?.state?.originalMapperWidth,
        imageMapperHeight: this?.state?.originalMapperHeight,
      });
      this.state.prismaZoom.current.reset();
      this.checkIsImageLoadable(this.state.selectedFile.fileNo, Number(value));
    }
  };

  onClickZoom = (value) => {
    if (value === "out") {
      this.state.prismaZoom.current.zoomOut(1);
    } else {
      this.state.prismaZoom.current.zoomIn(1);
    }
  };

  onZoomChange = (zoom) => {
    this.setState({
      zoom: zoom,
    });
  };

  rotateClick = (value) => {
    if (value === "right") {
      this.setState({ pageRotateAngle: this.state.pageRotateAngle + 90 });
    }
    if (value === "left") {
      this.setState({ pageRotateAngle: this.state.pageRotateAngle - 90 });
    }
  };

  fitToScreenClick = () => {
    this.state.prismaZoom.current.reset();
    this.setState({
      pageRotateAngle: 0,
      imageMapperWidth: this?.state?.originalMapperWidth,
      imageMapperHeight: this?.state?.originalMapperHeight,
    });
  };

  checkIsImageLoadable = (fileIndex, pageNo) => {
    this.setState({ imageLoaded: false });
    if (this?.state?.coorDataFromState[fileIndex]?.pages[pageNo].imageUrl) {
      const objImg = new Image();
      objImg.src =
        this?.state?.coorDataFromState[fileIndex]?.pages[pageNo].imageUrl;
      objImg.onload = () => {
        // setTimeout(function () {
        this.setState({ imageLoaded: true });
        // }, 2000);
      };
      objImg.onerror = () => {
        this.setState({ imageLoaded: true });
      };
    } else {
      this.setState({ imageLoaded: true });
    }
  };

  onClickFooterAction = (action) => {
    if (action === "cancel") {
      this.props.history.push("/pre-processor-dashboard");
    } else if (action == "previous") {
      this.props.history.push("/upload-template-data");
    } else {
      this.props?.child.current.mapData(action);
    }
  };

  clicked = (area, index, e) => {
    this.setState({
      selectedArea: area,
      areaIndex: index,
    });

    const dataToUpdate = this.state.data;
    dataToUpdate.areas[index] = {
      ...this.state.data.areas[index],
      preFillColor: "#ff00002e",
      fillColor: "#ff00002e",
    };
    this.setState({
      data: { ...dataToUpdate },
    });
  };

  onClickChangeOrientation = () => {
    this.setState({
      imageMapperWidth: this?.state?.imageMapperHeight,
      imageMapperHeight: this?.state?.imageMapperWidth,
    });
  };

  resetPdfHeighlights = () => {
    this?.setState({
      data: cloneDeep(this?.state?.dataCopy),
      coorDataFromState: cloneDeep(this?.state?.coorDataFromStateCopy),
    });
  };

  render() {
    return this?.state?.coorDataFromState?.length &&
      this?.state?.coorDataFromState[this.state.selectedFile?.fileNo]?.pages[
        this.state.selectedFile?.pageNo
      ].imageUrl &&
      this?.state?.coorDataFromState[this.state.selectedFile?.fileNo]?.pages[
        this.state.selectedFile?.pageNo
      ].areas ? (
      <Box className="paddingBox">
        <Grid container className="wrap" spacing={2}>
          {this?.props?.hideScreen ? (
            <Grid xs={this?.props?.leftgrid} item>
              <Box
                className={
                  this?.props?.isFullView ? "container-2" : "container-1"
                }
              >
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10}>
                    <div className="ac-processor-pdf-slider-body">
                      <section>
                        <div>
                          <Carousel
                            ssr
                            partialVisible
                            deviceType={this.props.deviceType}
                            itemClass="image-item"
                            responsive={this.state.responsive}
                            customRightArrow={<CustomRightArrow />}
                            customLeftArrow={<CustomLeftArrow />}
                          >
                            {this?.state?.coorDataFromState?.map(
                              (file, index) => {
                                return (
                                  <div
                                    className={
                                      this.state.selectedFile?.fileNo === index
                                        ? "ac-processor-slider-content selectedPDF"
                                        : "ac-processor-slider-content"
                                    }
                                    onClick={() =>
                                      this.handleFileChange(file.pdfName, index)
                                    }
                                    // key={index}
                                  >
                                    <PdfICon className="pdf-icon" />

                                    <Label
                                      text={file.pdfName}
                                      className="pdf-icon"
                                    ></Label>
                                  </div>
                                );
                              }
                            )}
                          </Carousel>
                        </div>
                      </section>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", padding: "5px 0px" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "4px",
                            ...(this.state.selectedFile?.pageNo + 1 <= 1
                              ? {
                                  opacity: "0.5",
                                  cursor: "not-allowed",
                                  pointerEvents: "none !important",
                                }
                              : ""),
                          }}
                          onClick={(e) =>
                            this.state.selectedFile?.pageNo !== 0 &&
                            this?.handlePageChange("prev")
                          }
                        >
                          <img
                            style={{ color: "#e72626" }}
                            src={AngleArrowLeft}
                            alt="prev page"
                          />
                        </IconButton>

                        <IconButton
                          sx={{
                            padding: "4px",
                            ...(this.state.selectedFile?.pageNo + 1 >=
                            this?.state?.coorDataFromState[
                              this.state.selectedFile?.fileNo
                            ]?.pages?.length
                              ? {
                                  opacity: "0.5",
                                  cursor: "not-allowed",
                                  pointerEvents: "none !important",
                                }
                              : ""),
                          }}
                          onClick={(e) =>
                            this.state.selectedFile?.pageNo + 1 !==
                              this?.state?.coorDataFromState[
                                this.state.selectedFile?.fileNo
                              ]?.pages?.length && this?.handlePageChange("next")
                          }
                        >
                          <img
                            style={{ color: "#ECECEC" }}
                            src={AngleArrowRight}
                            alt="next page"
                          />
                        </IconButton>
                      </div>

                      <>
                        <h1 style={{ ...styles }}>{`Page ${
                          this.state.selectedFile?.pageNo + 1
                        }/ ${
                          this?.state?.coorDataFromState[
                            this.state.selectedFile?.fileNo
                          ]?.pages?.length
                        }`}</h1>
                      </>
                    </div>

                    <MapToolBar
                      file={
                        this?.state?.coorDataFromState[
                          this.state.selectedFile?.fileNo
                        ]
                      }
                      handlePageChange={this.handlePageChange}
                      selectedFile={this.state.selectedFile}
                      searchPageClick={this.searchPageClick}
                      onClickZoom={this.onClickZoom}
                      zoom={this.state.zoom}
                      rotateClick={this.rotateClick}
                      fitToScreenClick={this.fitToScreenClick}
                      onClickChangeOrientation={this.onClickChangeOrientation}
                    />
                  </Grid>
                </Grid>
                <Grid className="view_pdf" md={12}>
                  <div
                    id="container"
                    style={{
                      transform: `rotate(${this.state.pageRotateAngle}deg)`,
                      transition: "transform 150ms ease", // smooth transition
                      // transformOrigin:" 50px 50px",
                      // overflow: "auto",
                      cursor: "grab",
                      left: [
                        90, 270, 450, 630, 810, 990, 1170, 1350, 1530, 1710,
                        1890,
                      ].includes(this.state.pageRotateAngle)
                        ? "14%"
                        : 0,
                      position: "relative",
                    }}
                  >
                    <PrismaZoom
                      maxZoom={10}
                      animDuration={0.5}
                      allowZoom={this.state.allowZoom}
                      ref={this.state.prismaZoom}
                      onZoomChange={this.onZoomChange}
                    >
                      {this.state?.imageLoaded ? (
<ImageMapper
                              src={this.state?.data?.imageUrl}
                              map={this.state?.data}
                              // width={600}
                              // height={768}
                              width={this?.state?.imageMapperWidth}
                              height={this?.state?.imageMapperHeight}
                              onClick={(area, index, e) =>
                                this.clicked(area, index, e)
                              }
                              imgWidth={this?.state?.imageMapperWidth}
                              natural={true}
                              responsive={true}
                              parentWidth={this?.state?.imageMapperWidth}
                            />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            // alignItems: "center",
                            paddingTop: 10,
                            height: 768,
                          }}
                        >
                          <CircularProgress />
                          <span
                            style={{
                              paddingLeft: "15px",
                              paddingTop: 10,
                            }}
                          >
                            Loading...
                          </span>
                        </Box>
                      )}
                    </PrismaZoom>
                  </div>
                </Grid>
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          <Grid xs={this?.props?.rightgrid} item>
            {this.props?.is_template_uploaded === true ? (
              <MapDataTable
                selectedFile={this.state?.selectedFile}
                selectedArea={this.state?.selectedArea}
                areaIndex={this.state?.areaIndex}
                parametersData={this.props?.parametersData}
                ref={this.props?.child}
                role="preProcessor"
                reProcess={this?.props?.reProcess}
                isDataRejectedByProcessor={
                  this?.props?.isDataRejectedByProcessor
                }
                isDataRejectedByValidator={
                  this?.props?.isDataRejectedByValidator
                }
                isFullView={this?.props?.isFullView}
                resetPdfHeighlights={this?.resetPdfHeighlights}
                entireBatchRejectedByPro={this?.props?.entireBatchRejectedByPro}
              />
            ) : this.props?.is_template_uploaded === false ? (
              <CNCTableWrapper
                selectedFile={this.state?.selectedFile}
                selectedArea={this.state?.selectedArea}
                areaIndex={this.state?.areaIndex}
                ref={this.props?.child}
                role="preProcessor"
                parametersData={this.props?.parametersData}
                columnsCount={this.props?.columnsCount}
                reProcess={this?.props?.reProcess}
                isDataRejectedByProcessor={
                  this?.props?.isDataRejectedByProcessor
                }
                isDataRejectedByValidator={
                  this?.props?.isDataRejectedByValidator
                }
                isFullView={this?.props?.isFullView}
                showContextMenu={false}
                isDisableOnChangeInput={true}
                isDisableOnBlur={true}
                resetPdfHeighlights={this?.resetPdfHeighlights}
                entireBatchRejectedByPro={this?.props?.entireBatchRejectedByPro}
              />
            ) : (
              <TableSkeleton />
            )}
          </Grid>
        </Grid>
      </Box>
    ) : (
      <ProgressBar />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state?.prePro?.coorData?.coordinatesDetails || [],
    parametersData:
      state?.prePro?.coorData?.mapping_details?.parametersData || [],
    is_template_uploaded: state?.prePro?.coorData?.is_template_uploaded,
    is_op_uploaded: state?.prePro?.coorData?.is_op_format_uploaded,
    columnsCount: state?.prePro?.coorData?.mapping_details?.columnsCount,
    proRowRejectionData:
      state?.prePro?.coorData?.mapping_details?.proRowRejectionData,
    reProcess: state?.prePro?.coorData?.mapping_details?.reProcess,
    isDataRejectedByProcessor:
      state?.prePro?.coorData?.mapping_details?.isDataRejectedByProcessor,
    isDataRejectedByValidator:
      state?.prePro?.coorData?.mapping_details?.isDataRejectedByValidator,
    validatorRowRejectionData:
      state?.prePro?.coorData?.mapping_details?.validatorRowRejectionData,
    entireBatchRejectedByPro:
      state?.prePro?.coorData?.mapping_details?.entireBatchRejectedByPro,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapData);
